import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resourcesToBackend from 'i18next-resources-to-backend';

// Import helpers
import { LOCAL_STORAGE_LANGUAGE, projectLanguages, pathnameLngDetector } from 'translations/helpers';

const namespaces = [
	'dictionary',
	'errors',
	'validator',
	'configurator',
	'payment',
	'login',
	'transaction',
	'user',
	'sections',
	'geolock',
	'button',
	'landing_page'
];

const languageDetector = new LanguageDetector();
languageDetector.addDetector(pathnameLngDetector);

i18n
	.use(resourcesToBackend((lng, ns) => import(`./translations/${ns}/${lng}.json`)))
	.use(languageDetector)
	.use(initReactI18next)
	.init({
		ns: namespaces,
		defaultNS: 'dictionary',
		fallbackLng: projectLanguages,
		debug: false,
		detection: {
			order: ['querystring', 'pathname', 'localStorage', 'htmlTag'],
			caches: ['localStorage'],
			lookupQuerystring: 'lang',
			lookupFromPathIndex: 0,
			lookupLocalStorage: LOCAL_STORAGE_LANGUAGE,
			supported: projectLanguages
		},
		interpolation: {
			escapeValue: false
		},
		react: {
			transSupportBasicHtmlNodes: true,
			transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'p', 'span', 'ul', 'li', 'ol']
		}
	});

export default i18n;
